// /* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
// import { AutoComplete } from '@mui/material';
import { Autocomplete, Stack } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import {Link} from 'react-router-dom';
// css
import "../../assets/css/home.css";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
// images
import banner from "../../assets/images/newBanner.png";
import SearchIcon from "@mui/icons-material/Search";
import colors from "../../assets/images/colors.gif";
import computer from "../../assets/images/computerScience.gif";
import data from "../../assets/images/data-analysis.gif";
import earth from "../../assets/images/earth.gif";
import green from "../../assets/images/green-calculator.gif";
import health from "../../assets/images/health-and-fitness.gif";
import javascript from "../../assets/images/javascript.gif";
import puzzle from "../../assets/images/puzzle.gif";
import business from "../../assets/images/open-business.gif";
import science from "../../assets/images/science-loader.gif";
import goggle from "../../assets/images/google-logo-icon-png-transparent-background-osteopathy.png";
import insta from "../../assets/images/insta.png";
import youtube from "../../assets/images/youtube.webp";
import fb from "../../assets/images/fb.png";
import harward from "../../assets/images/Harvard_shield_wreath.png";
import coursera from "../../assets/images/coursera-logo.png";
import udemy from "../../assets/images/udemy.png";
import yale from "../../assets/images/yale.jpg";
import duke from "../../assets/images/duke.png";
import ox from "../../assets/images/ox.png";
import edx from "../../assets/images/edx.png";
import FL from "../../assets/images/FutureLearn.webp";
import img1 from "../../assets/edubuk/1.png";
import img2 from "../../assets/edubuk/2.png";
import img3 from "../../assets/edubuk/3.png";
import img4 from "../../assets/edubuk/4.png";
import text1 from "../../assets/edubuk/text1.png";
import text3 from "../../assets/edubuk/text3.png";
import text4 from "../../assets/edubuk/text4.png";
import text5 from "../../assets/edubuk/text5.png";
import topBottom from "../../assets/images/topBottomVector.png";
import secBottom from "../../assets/images/secondVector.png";
import thirdBottom from "../../assets/images/thirdVector.png";
import fifthBottom from "../../assets/images/fifthVector.png";
import eduBanner1 from "../../assets/edubuk/eduBanner1.png";
import eduBanner2 from "../../assets/edubuk/eduBanner2.png";
// component
// import Navbar from "../utils/Navbar";
// import Footer from "../utils/Footer";
// FRC Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// data
import { FRD } from "../utils/data"; // old components utils
// APIs
import { path_track, search, search_track } from "../services/services";
import { Link, useLocation } from "react-router-dom";

// redux
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { tracker } from "../../App";
import TimeSpan from "../timeSpan/TimeSpan";
import useTrackTime from "../../hook/useTrackTime";

export default function Home(props) {
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearch] = useState("");
  const { auth } = useSelector((state) => state);
  const controllerRef = useRef(new AbortController());

  const timeSpan = useTrackTime();

  const secondSection = [
    { title: "Data Science", link: "/listing/Data-Science/true", image: data },
    { title: "Math", link: "/listing/Math/true", image: green },
    {
      title: "Health & LifeStyle",
      link: "/listing/Health-&-Lifestyle/true",
      image: health,
    },
    {
      title: "Science",
      link: "/listing/Science-&-Engineering/true",
      image: science,
    },
    {
      title: "Social Studies",
      link: "/listing/Social-Studies/true",
      image: earth,
    },
    {
      title: "Programming",
      link: "/listing/Programming-Languages/true",
      image: javascript,
    },
    {
      title: "Computer Science",
      link: "/listing/Computer-Science/true",
      image: computer,
    },
    { title: "Business", link: "/listing/Business/true", image: business },
    {
      title: "Arts & Design",
      link: "/listing/Arts-&-Design/true",
      image: colors,
    },
    { title: "Other", link: "/listing/Others/true", image: puzzle },
  ];

  useEffect(() => {
    path_track({
      email: auth.isAuth ? auth?.email : "Not Logged In",
      user_name: auth.isAuth ? auth?.name : "Not Logged In",
      time_span: 1,
      path: window.location.href,
    });
  }, []);
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 800);
    };
  };

  const handleChange = async (value) => {
    const controller = new AbortController();
    controllerRef.current = controller;

    let result = await search(value, controller.signal);
    // console.log("row data", result.data.data);
    //  await setSearch(value);
    setSuggestions(result.data.data);
  };

  const abortCall = () => {
    controllerRef.current.abort();
  };

  const fireSearch = () => {
    tracker("Search Query", "Search_homepage", searchValue, "search");
    if (auth.isAuth)
      search_track({
        user_name: auth.name,
        email: auth.email,
        search: searchValue,
      });
    else
      search_track({
        user_name: "Not Logged In",
        email: "Not Logged In",
        search: searchValue,
      });

    props.history(`/listing/${removeSpecialCharacters(searchValue)}`);
    // return history.push(`/listing`);
  };

  const searchOut = useCallback(debounce(handleChange), []);

  ///code added by pankaj
  const suggestionListRef = useRef();


function removeSpecialCharacters(text) {
  text = text.replace(/ /g, "-");
  return text;
}
  //hide list on select
  const handleHideList = () => {
    suggestionListRef.current.classList.add("hideList");
  };

  //show list on searchfield click
  const handleShowList = () => {
    suggestionListRef.current.classList.remove("hideList");
  };

  return (
    <Box mt={10}>
      {/* helmet tag  */}
      <Helmet>
        <title>Class Bazaar: Leading Platform for Online Education</title>
        <link rel="canonical" href="https://www.classbazaar.com/" />
        <meta name="keywords" content="Online Education, Best online courses" />
        <meta
          name="description"
          content="ClassBazaar : Learn from experts, build skills, and enjoy flexible, high-quality online learning tailored to your needs."
        />
      </Helmet>
      {/* helmet tag ends  */}
      {/* first section Banner  */}
      <Box className="topContainer">
        {/* // text  */}
        <Box className="topTextContainer" component="div">
          {/* heading */}
          <Typography className="topGradient heading">
            We Believe in Passion of Learning
          </Typography>
          <Typography className="topGradient" variant="button">
            LEARN FROM WORLD’S BEST PROFESSIONALS
          </Typography>
          <Box className="searchContainer">
            <Box
              className="searchBar"
              id="free-solo-2-demo"
              style={{ padding: "2px" }}
            >
              <TextField
                id="search-box"
                className="search-field"
                placeholder="Search Input"
                onBlur={() => {
                  abortCall();
                  searchValue !== "" ? handleShowList() : handleHideList();
                }}
                onChange={(e) => {
                  searchOut(e.target.value);
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  // console.log(e.key)
                  if (e.key === "Enter") {
                    searchOut(e.target.value);
                    setSearch(e.target.value);
                    fireSearch();
                  }
                }}
                InputProps={{
                  type: "search",
                }}
                value={searchValue}
                style={{ minWidth: "100%" }}
                onClick={() => {
                  handleShowList();
                }}
              />
            </Box>
            <Box
              className="searchSeggestionList"
              id="item-list"
              ref={suggestionListRef}
            >
              <ol>
                {searchValue ? (
                  <li key={"s-1"}>
                    <Box
                      style={{
                        marginBottom: "5px",
                        color: "#f15a29",
                        display: "flex",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      <SearchIcon size="small" />
                      <Typography className="line-clip-1" variant="body1">
                        Search all course matching{" "}
                        <strong>{`"${searchValue}"`}</strong>
                      </Typography>
                    </Box>
                    <Typography variant="caption">Courses</Typography>
                  </li>
                ) : (
                  <li key={"s-"}>
                    <Box
                      style={{
                        marginBottom: "10px",
                        color: "#f15a29",
                        display: "flex",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      <SearchIcon size="small" />
                      <Typography className="line-clip-1" variant="body1">
                        Type some keyword to search courses...
                      </Typography>
                    </Box>
                    <Typography variant="caption">Courses</Typography>
                  </li>
                )}
                {suggestions.map((item, index) => (
                  <Link
                    key={index}
                    to={`/listing/${removeSpecialCharacters(item?.title)}`}
                    onClick={() => {
                      setSearch(item.title);
                      handleHideList();
                    }}
                  >
                    <Box
                      component="b"
                      style={{ display: "flex", color: "grey", gap: "10px" }}
                    >
                      <ImportContactsIcon color={"primary"} />
                      <Typography variant="body2">
                        {" "}
                        {item.title} -{" "}
                        <Typography component="span" variant="subtitle2">
                          {item.provider}
                        </Typography>
                      </Typography>
                    </Box>
                  </Link>
                ))}
              </ol>
            </Box>
            {/* search bar added by Pankaj End Here */}

            {/* // button */}
            <IconButton
              // component={Link}
              // to={{
              //   pathname: '/listing',
              //   state: search,
              // }}
              onClick={fireSearch}
              className="searchButton"
            >
              <SearchIcon></SearchIcon>
            </IconButton>
          </Box>
          <Box className="extraButton">
            <Button
              size="small"
              onClick={() =>
                tracker("Home Page", "Click Filter Button", "Free Certificate")
              }
              component={Link}
              to={"/listing/Free/true"}
              className="searchBtn"
              variant="contained"
            >
              Free Certificate
            </Button>
            <Button
              size="small"
              onClick={() =>
                tracker("Home Page", "Click Filter Button", "Free Courses")
              }
              className="searchBtn"
              component={Link}
              to={"/listing/Free/true"}
              variant="contained"
            >
              Free Courses
            </Button>
            <Button
              component={Link}
              onClick={() => tracker("Home Page", "Click Filter Button", "edX")}
              to={"/listing/edX/true"}
              size="small"
              className="searchBtn"
              variant="contained"
            >
              EDX
            </Button>
            <Button
              size="small"
              onClick={() =>
                tracker("Home Page", "Click Filter Button", "Udemy")
              }
              component={Link}
              to={"/listing/Udemy/true"}
              className="searchBtn"
              variant="contained"
            >
              Udemy
            </Button>
          </Box>
        </Box>
        {/* // text  */}
        {/* banner */}
        <Box className="topBannerContainer">
          <img src={banner} alt="banner_image" />
        </Box>
        {/* banner ends */}
        <Box className={"topBottomVector"}>
          <img src={topBottom} alt="bottom" />
        </Box>
      </Box>
      {/* first section Banner ends */}

      {/* second section Banner  */}
      <Grid container className="secondContainer">
        <Grid item xs={12}>
          <Typography component={"h2"} className="secGradient">
            Find courses on any topic, We Believe in Passion of Learning
          </Typography>
        </Grid>
        <Grid item xs={12} p={3}>
          <Typography variant="caption">
            Discover best online courses from top universities around the world
            like MIT, Stanford, Harvard, IIT and many more.
          </Typography>
        </Grid>
        <Grid item xs={12} mb={7}>
          <Box className="secondImageSection">
            {secondSection.map((row, key) => (
              <Box
                key={key}
                component={Link}
                onClick={() =>
                  tracker(
                    "Homepage action",
                    "card click",
                    row.title,
                    "click GIF"
                  )
                }
                to={row.link}
                className="secCard"
              >
                <Box className={"secImage"}>
                  <img src={row.image} alt={key} />
                </Box>
                <Typography variant="button">{row.title}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} className={"topBottomVector"}>
          <img src={secBottom} alt={"bottomVec"}></img>
        </Grid>
      </Grid>
      {/* second section Banner ends  */}

      {/* third section Banner   */}
      <FutureReadyCourse />
      {/* third section Banner ends  */}

      {/* fourth section */}
      <BestCourses />
      {/* fourth section  ends*/}

      {/* fifth section  */}
      <TakeTest />
      {/* fifth section ends */}
    </Box>
  );
}

function FutureReadyCourse() {
  const data = FRD;
  const { auth } = useSelector((state) => state);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Grid container className="FRC_container">
        <Grid item xs={12} pr={2} pl={2}>
          <Typography className="FRC_show_more">Show More</Typography>
        </Grid>
        <Grid item xs={12} md={2.5}>
          <Typography className="FRC_title">Future Ready Courses</Typography>
        </Grid>
        <Grid item xs={12} md={9.5} mb={5} className="FRC_card">
          <Carousel
            className="card-section"
            dotListClass="custom-dot-list-style"
            keyBoardControl={true}
            autoPlaySpeed={1000}
            ssr={true}
            infinite={true}
            responsive={responsive}
          >
            {data.map((degree, key) => {
              return (
                <Box
                  className="outer_card"
                  component={Link}
                  onClick={() =>
                    tracker(
                      "Homepage action",
                      "click on future ready courses",
                      degree.name,
                      "click FRC section"
                    )
                  }
                  to={`/courseDetails${degree.url}`}
                >
                  <Box className="card_img">
                    <img src={degree.icon} alt={key} />
                  </Box>
                  <div className="name-section">{degree.name}</div>
                </Box>
              );
            })}
          </Carousel>
        </Grid>
        <Grid item xs={12} className={"topBottomVector"}>
          <img src={thirdBottom} alt={"bottomVec"}></img>
        </Grid>
      </Grid>
    </>
  );
}

// Your component
function BestCourses() {
  const university = [
    { logo: harward, link: "/listing/Harward-University/true", title: "Harward University" },
    {
      logo: yale,
      link: "/listing/Yale-University/true",
      title: "Yale University",
    },
    { logo: duke, link: "/listing/Duke-University/true", title: "Duke University" },
    { logo: ox, link: "/listing/Oxford-University/true", title: "Oxford University" },
  ];
  const provider = [
    { logo: udemy, link: "/listing/Udemy/true", title: "Udemy" },
    { logo: FL, link: "/listing/Future_Learn/true", title: "Future Learn" },
    { logo: coursera, link: "/listing/Coursera/true", title: "Coursera" },
    { logo: edx, link: "/listing/edX/true", title: "edX" },
  ];
  const institution = [
    { logo: goggle, link: "/listing/Google", title: "GOOGLE" },
    { logo: insta, link: "/listing/Instagram", title: "INSTAGRAM" },
    { logo: youtube, link: "/listing/You-Tube", title: "YOUTUBE" },
    { logo: fb, link: "/listing/Facebook", title: "FACEBOOK" },
  ];

  return (
    <>
      <Grid container className="fourthContainer">
        <Grid item xs={12}>
          <Typography component={"h1"} className="fourthHeading">
            Find The Best Courses
          </Typography>
        </Grid>
        <Grid item xs={12} p={3} mb={7} className="listSection">
          <Grid container className="listContainer">
            <Grid item xs={12} md={4} className="listBox">
              <Typography variant="h4">600+ Institutions</Typography>
              <Stack className="stack">
                {institution.map((row, key) => (
                  <Box className="list" key={key}>
                    <img src={row.logo} alt="provider_image" />
                    <Typography
                      key={key}
                      component={Link}
                      to={row.link}
                      variant="button"
                    >
                      {row.title}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} className="listBox">
              <Typography variant="h4">70 Providers</Typography>
              <Stack className="stack">
                {provider.map((row, key) => (
                  <Box className="list" key={key}>
                    <img src={row.logo} alt="provider_image" />
                    <Typography
                      key={key}
                      component={Link}
                      to={row.link}
                      variant="button"
                    >
                      {row.title}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} className="listBox">
              <Typography variant="h4">1000+ Universities</Typography>
              <Stack className="stack">
                {university.map((row, key) => (
                  <Box className="list" key={key}>
                    <img src={row.logo} alt="provider_image" />
                    <Typography
                      key={key}
                      component={Link}
                      to={row.link}
                      variant="button"
                    >
                      {row.title}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="topBottomVector">
          <img src={thirdBottom} alt="bottomVec" />
        </Grid>
      </Grid>
    </>
  );
}

function TakeTest() {
  return (
    <>
      <Grid container className="fifthContainer">
        {/* <Grid item xs={12} className="fifthImage">
          <Box className="eduImageCOntainer">
            <Box className="itemIMG">
              <img src={img1} alt="img1" />
              <img className="textImg img1 " src={text1} alt="img1" />
            </Box>
            <Box className="itemIMG">
              <img src={img2} alt="img2" />
            </Box>
            <Box className="itemIMG">
              <img src={img3} alt="img3" />
              <img className="textImg img3 " src={text3} alt="img3" />
            </Box>
            <Box className="itemIMG">
              <img src={img4} alt="img4" />
              <img className="textImg img4" src={text4} alt="img3" />
            </Box>
            <Box className="itemIMG">
              <img src={img3} alt="img5" />
              <img className="textImg img5 " src={text5} alt="img5" />
            </Box>
          </Box>
        </Grid> */}
        <Grid item xs={12} className="fifthImage">
          <Box className="eduImageCOntainer">
            <Box className="itemIMG2">
              <img src={eduBanner1} alt="img1" />
            </Box>
            <Box className="itemIMG2">
              <img src={eduBanner2} alt="img1" />
            </Box>
          </Box>
        </Grid>
        <br></br>
        <Grid item xs={12}>
          <Typography
            sx={{ textTransform: "uppercase" }}
            className="fifthHeading"
          >
            Skills you need to be ready for the next wave of emerging
            technology.
          </Typography>
          <center>
            <Typography className="subFifthHeading" variant="button">
              Powered By EduBuk
            </Typography>
          </center>
        </Grid>
        <Grid item xs={12} mb={7} className="fifthContainer">
          <center>
            <Button
              onClick={() =>
                tracker(
                  "Homepage action",
                  "Button clicked Take Test",
                  "Take Test"
                )
              }
              component={Link}
              // to={"/edubuk"}
              to={"/MIIT"}
              variant="contained"
            >
              See Test
            </Button>
          </center>
        </Grid>
        <Grid item xs={12} className={"topBottomVector"}>
          <img src={fifthBottom} alt={"bottomVec"}></img>
        </Grid>
      </Grid>
    </>
  );
}
